.formGroup {
  padding: 0;
}
.comboBooksSelectionZone {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.booksList,
.booksInComboList {
  display: flex;
  width: 45%;
  height: 50vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
}
.booksListTitle,
.comboListTitle {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 5px;
}
.adderBtn {
  display: flex;
  width: 20%;
  height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.booksListUL,
.booksListULcombo {
  width: 100%;
  margin-top: 10px;
  height: 18rem;
  overflow-y: scroll;
  display: block;
}
.booksListULcombo {
  height: 20rem;
}
.booksListItem {
  display: block;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.booksListItemDiv {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.booksListItemText {
  font-weight: bolder;
}
.booksListItemIcon {
  border-radius: 5px;
  border: 1px solid #c6c6c6;
  background-color: #ffffff;
  padding: 0.1rem 0.5rem;
}
.booksListItemIcon:hover {
  cursor: pointer;
}
.booksListItem svg path,
svg rect {
  fill: #0b9e23;
}
.comboMinusIcon svg path,
svg rect {
  fill: #d80902;
}
.comboBtnsContainer {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-self: center;
  margin: 0 auto;
}
.comboBtnsContainer button,
.comboBtnsContainer a {
  width: 45%;
}

.gmTeoB {
  width: 100%;
  height: 315px;
  max-width: 325px;
  min-width: 315px;
  max-height: 315px;
  position: absolute;
  top: 100%;
  background-color: white;
  border: solid 1px #b7b7b7;
  border-radius: 6px;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);
}
.combo-created-ok {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 1.3rem;
  display: none;
}
.successUpdate {
  display: flex;
}
