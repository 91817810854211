.charpterListItem {
    padding: 10px !important;
    margin: 10px;
}
.chapterTitle {
    /* padding-right: 10px; */
    /* margin-right: 10px; */
    /* color: red; */
}

.audioContainer {
    margin: 0 10px;
}