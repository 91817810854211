
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #00264e;
}

::-webkit-scrollbar-thumb:hover {
  background: #00264e;
}
.btn-success, .btn-info {
  background: linear-gradient(231.72deg, #E44210 10.2%, #DB7B41 40.15%, #E2974B 66.97%, #E6A951 83.96%, #E8AF53 96.03%);
  border: 1px solid #D35A35;
  border-radius: 10px;
}
.btn-primary, .btn-danger {
  background: linear-gradient(45deg, #E6E4E4 4.79%, #AFAEAE 69.82%, #878887 101.75%);
  border: 1px solid #514E4E;
  border-radius: 10px;
  color: #6C6969;
}
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 100%;
  font-family: 'Raleway', sans-serif;
}
.componentContainer {
  display: flex;
  width: 100%;
}
.sideBar {
  width: 20%;
  /* background-color: red; */
}

.rightColumn {
  width: 100%;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  /* width: 66%; */
  width: 100%;
}

.headerSection {
  /* background-color: red; */
  background: linear-gradient(231.72deg, #E44210 10.2%, #DB7B41 40.15%, #E2974B 66.97%, #E6A951 83.96%, #E8AF53 96.03%);
  display: flex;
  width: 100%;
  height: 80px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  font-size: 1.5rem;
  color: #eee;
  font-weight: 600;
}

.headerContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.batchDetailHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  padding: 10px 20px;
  background-color: #e9e9e9;
  margin-top: -1%;
  height: 15rem;
}
.batchDetailGroup {
  padding: 5px 0px;
}
.batchDetailGroup span {
  font-size: 1.3rem;
}
.insideRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.tableBatchBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 70%;
  margin: 0 auto;
}

.tableBatchBtns button {
  width: 20%;
}

.tableBatchBtns a {
  margin-right: 10px;
}

.tableBatchBtns a svg path,
svg rect {
  fill: #eaebeb;
}

.tableBatchBtns button svg path,
svg rect {
  fill: #eaebeb;
}
.loaderUpdateHolder svg path,
svg rect {
  fill: #17a2b8;
}
.mainLoaderHolder svg path,
svg rect {
  fill: #17a2b8;
}
.downloableQRHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 2rem 0.5rem 2rem 0.5rem;
}
.downloableQRHolder a {
  margin-top: 20px;
}
.holderBtnsDownload {
  display: flex;
  width: 50%;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.holderBtnsDownload button {
  margin-top: 0px;
}
.pin {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
}
.updateMsg {
  font-size: 1.3rem;
  padding: 6px 10px;
  letter-spacing: 2px;
  box-sizing: border-box;
  font-weight: bolder;
}
.successUpdate {
  color: #28a745;
}
.errorUpdate {
  color: #dc3545;
}
.editFormContainer {
  display: flex;
  justify-content: center;
  width: 60%;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 5%;
}
.formGroup {
  padding: 10px 0px;
  margin-bottom: 2%;
  width: 100%;
}
.formGroupDual {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
}
.formGroupDualItem {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.editImage {
  width: 50%;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
}
.radioGroupForm {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  margin-bottom: 2%;
}
.switchContainer svg path,
.switchContainer svg rect {
  fill: #eee;
}
.switchContainer {
  display: flex;
  padding: 10px 0px;
  justify-content: flex-start;
  align-items: center;
}
.switchContainer span {
  margin-left: 10px;
  font-weight: bolder;
}
.keypad {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.keypad button,
.keypad a {
  margin-right: 10px;
}
.txtModalNewBook {
  font-size: 1.8rem;
}
.txt2ModalNewBook {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.modalBtnsContainer2 {
  width: 100%;
  text-align: center;
}

.headerBooks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.booksColumns {
  display: flex;
  flex-direction: column;
  padding-top: 1%;
}
.bookListContainer {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  width: 80%;
  padding: 10px;
  margin: 0 auto;
  margin-top: 10px;
}
.bookImageContainer {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
}
.bookImage {
  height: 100px;
}
.bookTextContainer {
  display: flex;
  width: 80%;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}
.outstandingTitle {
  background-color: #28a745;
  color: #f8f9fa;
  font-weight: bolder;
  border-radius: 5px;
}
.bookTitle {
  font-size: 1.5rem;
  padding: 0px 5px;
}
.bookButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bookTableBtns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bookTableBtns a {
  margin-right: 10px;
}

.bookTableBtns a svg path,
svg rect {
  fill: #eaebeb;
}

.bookTableBtns button svg path,
svg rect {
  fill: #eaebeb;
}
.styles_modal__gNwvD {
  margin-top: 2%;
}
.modalContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modalTitle {
  font-size: 2rem;
  border-bottom: 1px solid #c6c6c6;
  width: 100%;
  text-align: center;
  margin-bottom: 3%;
}
.modalText {
  font-size: 1.5rem;
  padding: 10px;
  margin-bottom: 5%;
}
.modalBtnsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 50%;
}

.modalBtnsContainer button {
  width: 40%;
}
.booksListSubHeader {
  display: flex;
  flex-direction: row;
  padding: 0px 15px;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.catTableBtns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.catTableBtns a {
  margin-right: 10px;
}

.catTableBtns a svg path,
.catTableBtns svg rect {
  fill: #eaebeb;
}

.catTableBtns button svg path,
.catTableBtns svg rect {
  fill: #eaebeb;
}
.deleteLoader a svg path,
.deleteLoader svg rect {
  fill: #17a2b8;
}
.catSubheaderStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}
.catSubSubHeader {
  display: flex;
  flex-direction: row;
  padding: 0px 15px;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 45%;
}
.catListTitle {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #c6c6c6;
}
.catListHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}
.orderBtnsHolder {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  width: 15%;
}
.catBtnsSubHeaderHolder {
  padding: 0px 10px;
}
.catBtnsSubHeaderHolder a {
  margin-left: 10px;
}

ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-bottom: 0.5px solid rgba(250, 250, 250, 0.5);
}

ul li {
  width: 100%;
}

.likTag {
  text-decoration: none;
  color: #fff;
}

.likTag:hover {
  text-decoration: none;
  color: #fff;
}

.headerLink {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  padding-left: 5%;
}

.iconHolder {
  width: 20%;
  /* color:#fff !important; */
}

.sidebarHeader {
  /* background-color: red; */
  background: linear-gradient(231.72deg, #E44210 10.2%, #DB7B41 40.15%, #E2974B 66.97%, #E6A951 83.96%, #E8AF53 96.03%);
  /* padding: 20px; */
  font-size: 1.5rem;
  color: #eee;
  font-weight: 600;
  height: 80px;
}
.logoSidebar {
  width: 70px;
  margin-top:10px;
}
.spanHolder {
  width: 80%;
}

svg {
  margin-left: 5%;
}

.headerLink:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.fixedContent {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  /* width: 12%; */
  /* background-color: #002c33; */
  background: linear-gradient(231.72deg, #E44210 10.2%, #DB7B41 40.15%, #E2974B 66.97%, #E6A951 83.96%, #E8AF53 96.03%);
}
.reportsHolderTitle {
  /* background-color: #007386; */
  /* background: linear-gradient(231.72deg, #E44210 10.2%, #DB7B41 40.15%, #E2974B 66.97%, #E6A951 83.96%, #E8AF53 96.03%); */
}
.reportsTitle span {
  color: #ffffff;
}
.smallTitle {
  font-size: 1rem;
}

.homeContainer {
  display: flex;
  width: 88%;
  flex-direction: column;
}
.headerHolder {
  /* background-color: #17a2b8; */
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem;
}
.logoHeader {
  width: 10rem;
  margin-right: 1rem;
}
.homeTitle {
  padding: 20px;
  background-color: #17a2b8;
  font-size: 1.5rem;
  color: #eee;
  font-weight: 600;
}

.homeContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
}
.homeEachContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  padding: 1rem;
}

.homeEachTitle {
  font-size: 2rem;
}
.numbers {
  font-size: 4rem;
  font-weight: bolder;
  color: #dc3545;
}

.mainHomeContainer {
  width: 100%;
}
.generalStatusTitleContainer {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #002c33;
}
.generalStatusTitle {
  color: #ffffff;
  font-size: 1.3rem;
}
.filterBoxContainer {
  display: flex;
  padding-left: 1rem;
  flex-direction: row;
}
.seeBatchDetailBtn {
  margin-left: 5px;
  margin-right: 5px;
}
.homeTableHolder {
  display: flex;
  width: 100%;
  margin-top: 5px;
}
.ReactTable {
  width: 100% !important;
}

.datePickerContainer {
  display: flex;
}
.dateFilterHolder {
  width: auto;
}
.datesHolder {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.2rem;
  width: auto;
  padding: 10px;
  margin-right: 5%;
  padding-bottom: 0.4%;
}

.loader {
  margin: 0 0 2em;
  height: 100px;
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
}
/*
svg path,
svg rect {
  fill: #ee3a31;
} */

.loginWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-image: url('../assets/audio4.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.loginContainer {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-radius: 5px;
}

.loginContainer input {
  margin-bottom: 10px;
}
.loginTitle {
  width: 100%;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  color: #eee;
  font-size: 1.5rem;
}
.loginErrorMsg {
  width: 100%;
  text-align: center;
  border-radius: 5px;
  background-color: #ff3e3e;
  margin-bottom: 10px;
  color: #fff;
  padding: 10px;
  display: none;
}

.newBookMsg {
  display: none;
}
.newBookForm {
  display: flex;
  width: 60%;
  margin: 0 auto;
  flex-direction: column;
}
.formLabel {
  font-size: 1.5rem;
}
.formGroup {
  padding: 10px;
  margin-bottom: 0;
}
.btn-save-book {
  width: 40%;
  margin: 0 auto;
  margin-top: 20px;
}
.btnAddChapter a svg path,
svg rect {
  fill: #000;
}
.unGrupo {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}
.formGroupDualChapter {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.formGroupDualChapter .form-group {
  margin-bottom: 0;
}
.formLabel {
  font-size: 1.3rem;
}
.warningTitle {
  font-size: 2rem;
  padding: 10px 20px;
  margin-bottom: 10px;
  border: 1px solid #bd2130;
  border-radius: 5px;
}
.hidden {
  display: none;
}
.charpterListItem {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border: 1px solid #c6c6c6;
}
.chaptersContainer {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chapterTitle {
  font-weight: bold;
}

.catForm {
  width: 40%;
  margin: 0 auto;
}
.btnGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  margin: 0 auto;
}
.btnGroup button {
  width: 40%;
}
.catMsgOK {
  width: 70%;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: #28a745;
  border: 1px solid #28a745;
  border-radius: 5px;
  padding: 5px;
  margin: 0 auto;
}
.catMsgERROR {
  width: 70%;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: #dc3545;
  border: 1px solid #dc3545;
  border-radius: 5px;
  padding: 5px;
  margin: 0 auto;
}
.newCatLoaderCont {
  display: flex;
  justify-content: center;
}

.posForm {
  display: flex;
  width: 40%;
  flex-direction: column;
  margin: 0 auto;
}
.posForm .posFormGroup {
  margin-bottom: 2rem;
}
.btnGroup {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-self: center;
}
.loaderHolder {
  text-align: center;
}
.posMsgHolder {
  text-align: center;
}
.posMsg {
  font-size: 1.5rem;
  color: #0b874a;
}

.newUserMainCont {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.newUserTitleForm {
  width: 60%;
  text-align: center;
  background-color: #17a2b8;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  letter-spacing: 0.3rem;
  color: #eee;
}

.newUserFormCont {
  display: flex;
  width: 30%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #4950573d;
  padding: 10px;
  border-radius: 5px;
}

.newUserFormCont input {
  margin-bottom: 10px;
}
.newUserBtnsCont {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.newUserErrorMsg {
  width: 100%;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  /* background-color: red; */
  color: #eee;
  border-radius: 5px;
  margin-top: 10px;
  display: none;
}

.newUserOkMsg {
  width: 100%;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: forestgreen;
  color: #eee;
  border-radius: 5px;
  margin-top: 10px;
  display: none;
}

.posTableBtns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.posTableBtns a {
  margin-right: 10px;
}

.posTableBtns a svg path,
svg rect {
  fill: #eaebeb;
}

.posTableBtns button svg path,
svg rect {
  fill: #eaebeb;
}

.noData {
  display: flex;
  width: 100%;
  border-top: 1px solid #c6c6c6;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.qrHolder {
  width: 15rem;
}
.qrForm {
  display: flex;
  width: 40%;
  flex-direction: column;
  margin: 0 auto;
}
.qrFormGroup {
  display: flex;
  flex-direction: column;
}
.qrForm .qrFormGroup {
  margin-bottom: 2rem;
}
.batchMsg {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  color: #ffffff;
  background-color: #0a9755;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}
.qrTableBtns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.qrTableBtns a {
  margin-right: 10px;
}

.qrTableBtns a svg path,
svg rect {
  fill: #eaebeb;
}

.qrTableBtns button svg path,
svg rect {
  fill: #eaebeb;
}
.btnsGenerateQRHolder {
  display: flex;
  width: 60%;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 auto;
}
.qrCodesListTopBar {
  padding: 10px;
  text-align: right;
  padding-top: 0;
}

.detailContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 1rem;
}
.detailTitle {
  font-size: 2rem;
  padding: 0rem 0rem;
  border-bottom: 1px solid #c6c6c6;
  margin: 1rem 0rem;
}
.detailImage {
  width: 50%;
  border-radius: 5px;
  margin-bottom: 1rem;
}
.buyDate,
.pointOfSale,
.mpVoucher,
.extraDataBatchId,
.extraDataQrId,
.extraDataUser,
.extraDataPin {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.extraData {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}
.saleBtnHolder {
  display: flex;
  width: 15%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.saleBtnHolder a {
  width: 40%;
}

.dateFilterHolder {
  width: 15%;
}

.userCard {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
  margin-left: 5px;
}

.cardTitle {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
}

.cardBtnHolder {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.cardBtnHolder p {
  margin: 0;
  display: flex;
  width: 50%;
}
.userCardBtns {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.userCardBtns button {
  margin-bottom: 3px;
}

.datailsContainer {
  position: fixed;
  left: 30%;
  top: 15%;
  display: none;
}

.usersColumns {
  display: flex;
  flex-direction: column;
  padding-top: 1%;
}
.appSearchInput {
  width: 50%;
}

.appSearchInput input, .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background: #F0F0F0;
  border: 1px solid #DB7B40;
  border-radius: 10px;

  /* background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
}
.ReactTable .rt-tbody .rt-td {
  text-align: center;
}
.subHeader {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1%;
}
/*
svg path,
svg rect {
  fill: #129aa0;
} */

.masiveDownload {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  z-index: 9999999;
  justify-content: flex-start;
}
.masiveDownloadQrs {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.masiveDownloadBtns {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
}
.masiveDownloadBtns button {
  width: 45%;
}
.comboBooksList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
}
.comboBooksList span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
  text-align: left;
}
.infinityHolder{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.infinityHolder input{
  width: 2vw;
  height: 2vw;
  margin-left: 1vw;
}